import data from '../utils/articles.json'
import React from 'react';
import { Wrap, WrapItem, Center,Stack, Box, Text, Image } from '@chakra-ui/react'; 

const articles = data.map(({ _id, content, image, title }) => ({ _id,content, image, title }));

const ArticleScreen = () => {
  return (
    <Wrap spacing='30px' justify='center'>
       <Text fontSize='3xl' fontWeight='bold' color='purple.500' textAlign='center'pt={4}>
                Good to Know
              </Text>
      {articles.map((article) => (
        <WrapItem key={article._id}>
          <Stack direction='row' spacing={4} justifyContent='center' alignItems='center'>
            <Box flex='1' borderWidth={1} borderRadius='lg'my='3'>
            <Image p={1} width='300px' height='220px' src={article.image} roundedTop='lg' />
              <Text mb={2} textAlign='center'>
                {article.title}
              </Text>
            </Box>
         </Stack>
        </WrapItem>
      ))}
    </Wrap>
  );
};

export default ArticleScreen;
