import React from 'react';
import { Wrap, Stack, Text, HStack, Button, Textarea,Input,useToast,Box,Icon} from '@chakra-ui/react'; 
import { StarIcon } from '@chakra-ui/icons';
import { useState, useRef} from 'react';



const ScanScreen = () => {
    const [rating, setRating] = useState(0);
    const toast = useToast();
    const fileInput = useRef();

    const onSubmit = () => {
        toast({
            description: 'You have been submit.',
            duration: 1000,
            status: 'success',
            isClosable: true
          });
    };
    const handleButtonClick = () => {
        fileInput.current.click();
      };
    
  return (
    <Wrap spacing='30px' justify='center' minHeight='90vh'>
        <Stack spacing='30px' justify='center' alignItems='center'>
      <Text fontSize='3xl' fontWeight='bold' color='purple.500' textAlign='center' pt={10}>
  Scan and Share
</Text>
<Textarea size='md'placeholder={`Scan Your Receipt`} />
<Text fontSize='xl' fontWeight='bold' color='purple.500' textAlign='center'>
  or
</Text>
<Button onClick={handleButtonClick} colorScheme="purple" size="lg">
        Upload Your Receipt
      </Button>
      </Stack>
      <Input
        ref={fileInput}
        type="file"
        display="none"
      />
        <Stack mb='20px'>
                    <Wrap>
                    <HStack spacing='6px'>
          {[1, 2, 3, 4, 5].map(i => (
            <Button variant='outline' onClick={() => setRating(i)}>
              <StarIcon color={rating >= i ? 'purple.400' : 'gray.200'} />
            </Button>
          ))}
        </HStack>
                    </Wrap>
                    <Input my='4'placeholder='Review title'/>
                    <Textarea size='lg'placeholder={`The product is...`} />
                    <Button w='140px' colorScheme='purple' onClick={() => onSubmit()}>
                      Submit
                    </Button>
                  </Stack>
          
    </Wrap>
  );
};

export default ScanScreen;
