import React from 'react';
import { Wrap, WrapItem, Center,Stack, Box, Text, Image,HStack,Flex } from '@chakra-ui/react'; 

const RewardScreen = () => {
    return (
      <Wrap spacing='30px' justify='center'>
        <Stack direction='row' spacing={4} justifyContent='center' alignItems='center'>
        <Box flex='1'borderRadius='lg'mx='10'>
        <Text fontSize='3xl' fontWeight='bold' color='purple.500' textAlign='center'pt={4}>
                  Rewards
                </Text>
                <Box
        bg="purple.300"
        w="100px"
        h="100px"
        borderRadius="50%"
        margin="auto"
      >
          <Center h="100%">
              <Box>
          <Text color="puple.500" fontSize='xl' fontWeight='bold' textAlign='center'>Points</Text>
          <Text color="puple.700" fontSize='3xl' fontWeight='bold' textAlign='center'>144</Text>
          </Box>
        </Center>
      </Box>
      </Box>
      </Stack>
      <Stack>  <Box>
                <Image  mx='auto'md='5'p={2} width='300px' height='220px'roundedTop='lg' src='../images/rewards1.jpg' />
                <Text fontSize='xl' fontWeight='bold' color='purple.700' textAlign='center'pt={4}>
                  From 50
                </Text>
                </Box>
                <Box>
                <Image mx='auto'mb='5'p={2} width='300px' height='220px'roundedTop='lg'src='../images/rewards2.jpg'/>
                <Text fontSize='xl' fontWeight='bold' color='purple.700' textAlign='center'pt={4}>
                  From 30
                </Text>
                </Box>
                <Box>
                <Image mx='auto'mb='5'p={2} width='300px' height='220px'roundedTop='lg'src='../images/rewards3.jpg'/>
                <Text fontSize='xl' fontWeight='bold' color='purple.700' textAlign='center'pt={4}>
                  From 40
                </Text>
                </Box>
                <Box>
                <Image mx='auto'mb='5'p={2} width='300px' height='220px'roundedTop='lg'src='../images/rewards4.jpg'/>
                <Text fontSize='xl' fontWeight='bold' color='purple.700' textAlign='center'pt={4}>
                  From 20
                </Text>
                </Box>
                </Stack>
      </Wrap>
    );
  };
  
  export default RewardScreen;
  