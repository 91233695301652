import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import ProductsScreen from './screens/ProductsScreen';
import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import Footer from '../src/components/Footer';
import LandingScreen from './screens/LandingScreen';
import LoginScreen from './screens/LoginScreen';
import RegistrationScreen from './screens/RegistrationScreen';
import ProfileScreen from './screens/ProfileScreen';
import CheckoutScreen from './screens/CheckoutScreen';
import YourOrdersScreen from './screens/YourOrdersScreen';
import OrderSuccessScreen from './screens/OrderSuccessScreen';
import AdminConsoleScreen from './screens/AdminConsoleScreen';
import ArticleScreen from './screens/ArticleScreen';
import ScanScreen from './screens/ScanScreen';
import HomeScreen from './screens/HomeScreen';
import RewardScreen from './screens/RewardScreen'

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Navbar />
        <main>
          <Routes>
            <Route path='/' element={<LandingScreen />}></Route>
            <Route path='/home' element={<HomeScreen />}></Route>
            <Route path='/products' element={<ProductsScreen />}></Route>
            <Route path='/product/:id' element={<ProductScreen />}></Route>
            <Route path='/cart' element={<CartScreen />}></Route>
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/registration' element={<RegistrationScreen />} />
            <Route path='/articles' element={<ArticleScreen />} /> 
            <Route path='/scan' element={<ScanScreen />} /> 
            <Route path='/profile' element={<ProfileScreen />} />
            <Route path='/rewards' element={<RewardScreen />} />
            <Route path='/checkout' element={<CheckoutScreen />} />
            <Route path='/your-exchange' element={<YourOrdersScreen />} />
            <Route path='/order-success' element={<OrderSuccessScreen />} />
            <Route path='/admin-console' element={<AdminConsoleScreen />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
