import {
  Box,
  Flex,
  Icon,
  Button,
  ButtonGroup,
  Container,
  Divider,
  IconButton,
  Link,
  Stack,
  Image,
  Skeleton,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import * as React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { GiCampingTent } from 'react-icons/gi';
import { IoBookOutline } from "react-icons/io5";
import { IoCamera } from "react-icons/io5";
import { LuShoppingBag } from "react-icons/lu";
import{FaPen} from "react-icons/fa";
import { useState } from 'react';
// import { Logo } from './Logo';

const Footer = () => {
  const [bookIsHovering, setBookIsHovering] = useState(false);
  const [cameraIsHovering, setCameraIsHovering] = useState(false);
  const [bagIsHovering, setBagIsHovering] = useState(false);
  const [penIsHovering, setPenIsHovering] = useState(false);

  return (
  <Box w='100%'bg={useColorModeValue('gray.100', 'gray.900')}>
    <Container as='footer' role='contentinfo' maxW='7xl'>
      <Stack
        spacing='8'
        direction={{ base: 'column', md: 'row' }}
        justify='space-between'
        py={{ base: '3', md: '3' }}
      >
        <Stack spacing={{ base: '6', md: '8' }} align='center'>
          <Flex alignItems='center'justifyContent='space-between'>
            <Link as={ReactLink} to='/articles' style={{ textDecoration: 'none' }} onMouseEnter={() => setBookIsHovering(true)}
            onMouseLeave={() => setBookIsHovering(false)}>
          <Icon as={IoBookOutline} w='10' h='10' mx='3' color={bookIsHovering ?'purple.700':'purple.500'}/>
          </Link>
          <Link as={ReactLink} to='/scan' style={{ textDecoration: 'none' }} onMouseEnter={() => setCameraIsHovering(true)}
            onMouseLeave={() => setCameraIsHovering(false)}>
          <Icon as={IoCamera} w='10' h='10' mx='3' color={cameraIsHovering ?'purple.700':'purple.500'} />
          </Link>
            <Link
            as={ReactLink}
            to='/home'
            style={{ textDecoration: 'none' }}
          > 
          
        <Image
          src='images/icon.jpg'
          borderRadius='full'
          alt='Landing Image'
          fallback={<Skeleton />}
          maxH='60px'
          minW='60px'
          objectFit='cover'
          flex='1'
        />
         </Link>
         <Link as={ReactLink} to='/rewards' style={{ textDecoration: 'none' }} onMouseEnter={() => setBagIsHovering(true)}
            onMouseLeave={() => setBagIsHovering(false)}>
        <Icon as={LuShoppingBag} w='10' h='10' mx='3' color={bagIsHovering ?'purple.700':'purple.500'} />
        </Link>
        <Link as={ReactLink} to='/products' style={{ textDecoration: 'none' }} onMouseEnter={() => setPenIsHovering(true)}
            onMouseLeave={() => setPenIsHovering(false)}>
        <Icon as={FaPen} w='8' h='8' mx='3' color={penIsHovering ?'purple.700':'purple.500'}/>
        </Link>
          </Flex>
        </Stack>
      </Stack>
      <Divider />
      <Stack pt='1' pb='1' justify='space-between' direction={{ base: 'column-reverse', md: 'row' }} align='center'>
        <Text fontSize='sm' color='subtle'>
          &copy; {new Date().getFullYear()}  O=C=O, Inc. All rights reserved.
        </Text>
      </Stack>
    </Container>
  </Box>
  )
}

export default Footer;
