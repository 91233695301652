import React from 'react';
import { Wrap, WrapItem, Center,Stack, Box, Text, Image,HStack,Flex } from '@chakra-ui/react'; 
import carousel1 from '../utils/carousel1.json'
import carousel2 from '../utils/carousel2.json'

const HomeScreen = () => {
  return (
    <Wrap spacing='30px' justify='center'>
      <Stack direction='row' spacing={4} justifyContent='center' alignItems='center'>
      <Box flex='1'borderRadius='lg'mx='10'>
      <Text fontSize='3xl' fontWeight='bold' color='purple.500' textAlign='center'pt={4}>
                Hi, Bobo
              </Text>
              <Box
      bg="purple.300"
      w="100px"
      h="100px"
      borderRadius="50%"
      margin="auto"
    >
        <Center h="100%">
            <Box>
        <Text color="puple.500" fontSize='xl' fontWeight='bold' textAlign='center'>Points</Text>
        <Text color="puple.700" fontSize='3xl' fontWeight='bold' textAlign='center'>144</Text>
        </Box>
      </Center>
    </Box>
    </Box>
    </Stack>
              <Text fontSize='2xl' fontWeight='bold' color='purple.700' textAlign='center'pt={4}>
                Daily Challenges
              </Text>
              <Image  mx='auto'md='5'p={2} width='300px' height='220px'roundedTop='lg' src={carousel1[0].image} />
    <Stack>
              <Text fontSize='2xl' fontWeight='bold' color='purple.700' textAlign='center'pt={4}>
                Something new in reviews
              </Text>
              <Image mx='auto'mb='5'p={2} width='300px' height='220px'roundedTop='lg'src={carousel2[1].image}/>
              </Stack>
    </Wrap>
  );
};

export default HomeScreen;
