import { useParams } from 'react-router-dom';
import {
  Box,
  Image,
  Text,
  Wrap,
  Flex,
  Badge,
  Heading,
  HStack,
  Button,
  SimpleGrid,
  Stack,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
  Tooltip,
  Textarea,
  Input
} from '@chakra-ui/react';
import { MinusIcon, StarIcon, SmallAddIcon } from '@chakra-ui/icons';
import { BiPackage, BiCheckShield, BiSupport } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { createProductReview, getProduct, resetProductError } from '../redux/actions/productActions';
import { addCartItem } from '../redux/actions/cartActions';
import { useEffect, useState } from 'react';

const ProductScreen = () => {
  const [amount, setAmount] = useState(1);
  let { id } = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.products);
  const { loading, error, product, reviewSend } = productList;
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const cartInfo = useSelector((state) => state.cart);
  const { cart } = cartInfo;

  useEffect(() => {
    dispatch(getProduct(id));
    if (reviewSend) {
      toast({ description: 'Product review saved.', duration: 1000, status: 'success', isClosable: true });
      dispatch(resetProductError());
    }
  }, [dispatch, id, reviewSend, toast]);

  const changeAmount = (input) => {
    if (input === 'minus') {
      setAmount(amount - 1);
    } else if (input === 'plus') {
      setAmount(amount + 1);
    }
  };

  const hasUserReviewed = () => product.reviews.some((item) => item.user === userInfo._id);

  const onSubmit = () => {
    dispatch(createProductReview(product._id, userInfo._id));
  };
  const addItem = (id) => {
    if (cart.some((cartItem) => cartItem.id === id)) {
      toast({
        description: 'This item is already in your cart. Go to your cart to change the amount.',
        duration: 1500,
        status: 'error',
        isClosable: true
      });
    } else {
      dispatch(addCartItem(product._id, amount));
      toast({
        description: 'Item has been added.',
        duration: 1000,
        status: 'success',
        isClosable: true
      });
    }
  };

  return (
    <Wrap spacing='30px' justify='center' minHeight='100vh'>
      {loading ? (
        <Stack direction='row' spacing={4}>
          <Spinner mt={20} thickness='6px' speed='0.65s' emptyColor='gray.200' color='purple.400' size='xl' />
        </Stack>
      ) : error ? (
        <Alert status='error'>
          <AlertIcon />
          <AlertTitle>Opps!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      ) : (
        product && (
          <Box
            maxW={{ base: '3xl', lg: '5xl' }}
            mx='auto'
            px={{ base: '4', md: '8', lg: '12' }}
            py={{ base: '6', md: '8', lg: '12' }}
          >
            <Stack direction={{ base: 'column', lg: 'row' }} align={{ lg: 'flex-start' }}>
              <Stack
                pr={{ base: '0', md: '12' }}
                spacing={{ base: '8', md: '4' }}
                flex='1.5'
                mb={{ base: '12', md: 'none' }}
              >
                {product.productIsNew && (
                  <Badge rounded='full' w='40px' fontSize='0.8em' colorScheme='green'>
                    New
                  </Badge>
                )}
                {product.stock === 0 && (
                  <Badge rounded='full' w='70px' fontSize='0.8em' colorScheme='red'>
                    None
                  </Badge>
                )}
                <Heading fontSize='4xl' fontWeight='extrabold'>
                  {product.name}
                </Heading>
                <Stack spacing='5'>
                  <Box>
                    <Flex mt='3'>
                      <HStack spacing='2px'>
                        <StarIcon color='purple.400' />
                        <StarIcon color={product.rating >= 2 ? 'purple.400' : 'gray.200'} />
                        <StarIcon color={product.rating >= 3 ? 'purple.400' : 'gray.200'} />
                        <StarIcon color={product.rating >= 4 ? 'purple.400' : 'gray.200'} />
                        <StarIcon color={product.rating >= 5 ? 'purple.400' : 'gray.200'} />
                      </HStack>
                      <Text fontSize='md' fontWeight='bold' ml='4px'>
                        {product.numberOfReviews} Reviews
                      </Text>
                    </Flex>
                  </Box>
                  <Text whiteSpace='pre-line' fontSize='md' fontWeight='bold' ml='4px'>{product.description}</Text>
                  <Text fontSize='2xl' fontWeight='bold'>
                      {product.price} points
                    </Text>
                </Stack>
              </Stack>
              <Flex direction='column' align='center' flex='1'>
                <Image src={product.image} alt={product.name} rounded='8px' />
              </Flex>
            </Stack>
            <Stack>
              <Text fontSize='xl' fontWeight='bold'>
                Reviews
              </Text>
              <SimpleGrid minChildWidth='300px' spacingX='40px' spacingY='20px'>
                {product.reviews.map((review) => (
                  <Box key={review._id}>
                    <Flex spacing='2px' alignItems='center'>
                      <StarIcon color='purple.400' />
                      <StarIcon color={review.rating >= 2 ? 'purple.400' : 'gray.200'} />
                      <StarIcon color={review.rating >= 3 ? 'purple.400' : 'gray.200'} />
                      <StarIcon color={review.rating >= 4 ? 'purple.400' : 'gray.200'} />
                      <StarIcon color={review.rating >= 5 ? 'purple.400' : 'gray.200'} />
                      <Text fontWeight='semibold' ml='4px'>
                        {review.title && review.title}
                      </Text>
                    </Flex>
                    <Box py='12px'>{review.comment}</Box>
                    <Text fontSize='sm' color='gray.400'>
                      by {review.name}, {new Date(review.createdAt).toDateString()}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
            </Stack>
          </Box>
        )
      )}
    </Wrap>
  );
};

export default ProductScreen;
