import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Skeleton,
  Stack,
  useColorModeValue,
  Text,
  Button
} from '@chakra-ui/react';
import { FaArrowRight } from 'react-icons/fa';
import { Link as ReactLink } from 'react-router-dom';
import { GiCampingTent } from 'react-icons/gi';

const LandingScreen = () => (
  <Box maxW='8xl' mx='auto' px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '40' }} minH='sm'>
    <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
      <Box
        width={{ lg: 'sm' }}
        transform={{ base: 'translateY(-50%)', lg: 'none' }}
        bg={{ base: useColorModeValue('purple.50', 'gray.700'), lg: 'transparent' }}
        mx={{ base: '6', md: '8', lg: '0' }}
        px={{ base: '6', md: '8', lg: '0' }}
        py={{ base: '6', md: '8', lg: '12' }}
      >
        <Stack spacing={{ base: '7', lg: '9' }} justifyContent='center' alignItems='center'>
          <Stack spacing={{ base: '2', lg: '4' }}>
              <Text fontSize='5xl' fontWeight='bold' color='purple.500' textAlign='center'>
                O=C=O
              </Text>
            <Heading size='lg' fontWeight='bold'textAlign='center'>
            An innovative app makes reducing carbon dioxide emissions fun and rewarding! <br />
            </Heading>
          </Stack>
          <HStack spacing='3' textAlign='center'>
            <Button
              as={ReactLink}
              to='/login'
              colorScheme='purple'
              fontWeight='bold'
              fontSize='xl'
              rightIcon={<FaArrowRight />}
            >
              Join Us Now!
            </Button>
          </HStack>
        </Stack>
      </Box>
      <Flex flex='1' overflow='hidden'>
        <Image
          src='images/landing_image.jpg'
          borderRadius='full'
          alt='Landing Image'
          fallback={<Skeleton />}
          maxH='600px'
          minW='300px'
          objectFit='cover'
          flex='1'
        />
      </Flex>
    </Stack>
  </Box>
);

export default LandingScreen;
