import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Icon,
  Text,
  useDisclosure,
  Button,
  Stack,
  useColorModeValue,
  useColorMode,
  useToast,
  Menu,
  MenuList,
  MenuButton,
  MenuDivider,
  MenuItem
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { BellIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { CgProfile } from 'react-icons/cg';
import { MdOutlineAdminPanelSettings, MdLocalShipping, MdLogout } from 'react-icons/md';
import { FiShoppingCart } from 'react-icons/fi';
import { GiCampingTent } from 'react-icons/gi';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/actions/userActions';

const ShoppingCartIcon = () => {
  const cartInfo = useSelector((state) => state.cart);
  const { cart } = cartInfo;
  return (
    <Flex>
      <Text fontStyle='italic' as='sub' fontSize='xs'>
        {cart.length}
      </Text>
      <Icon ml='-1.5' as={FiShoppingCart} h='4' w='7' alignSelf='center' />
      Cart
    </Flex>
  );
};

const links = [
  { linkName: 'Products', path: '/products' },
  { linkName: <ShoppingCartIcon />, path: '/cart' }
];

const NavLink = ({ path, children }) => (
  <Link
    as={ReactLink}
    to={path}
    px={2}
    py={2}
    rounded='md'
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700')
    }}
  >
    {children}
  </Link>
);

const Navbar = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();
  const [isHovering, setIsHovering] = useState(false);
  const user = useSelector((state) => state.user);
  const { userInfo } = user;
  const dispatch = useDispatch();
  const toast = useToast();

  const logoutHandler = () => {
    dispatch(logout());
    toast({
      description: 'You have been logout.',
      duration: 1000,
      status: 'success',
      isClosable: true
    });
  };
  return (
    <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
      <Flex h={16} alignItems='center' justifyContent='space-between'>
        {/* <IconButton
          size='md'
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        /> */}
        <HStack>
            <Flex alignItems='center'>
            {userInfo ? (
            <>
              <Menu>
                <MenuButton px='4' py='2' transition='all 0.2s' as={Button}>
                  {userInfo.name}
                  <ChevronDownIcon />
                </MenuButton>
                <MenuList>
                  <MenuItem as={ReactLink} to='/profile'>
                    <CgProfile />
                    <Text ml='2'>Profile</Text>
                  </MenuItem>
                  <MenuItem as={ReactLink} to='/your-exchange'>
                    <MdLocalShipping />
                    <Text ml='2'>Your Exchange</Text>
                  </MenuItem>
                  {userInfo.isAdmin === 'true' && (
                    <>
                      <MenuDivider />
                      <MenuItem as={ReactLink} to={'/admin-console'}>
                        <MdOutlineAdminPanelSettings />
                        <Text ml='2'>Admin Console</Text>
                      </MenuItem>
                    </>
                  )}
                  <MenuDivider />
                  <MenuItem onClick={logoutHandler}>
                    <MdLogout />
                    <Text ml='2'>Logout</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            <>
              <Button as={ReactLink} to='/login' p={3} fontSize='sm' fontWeight={600} variant='link'>
                Sign In
              </Button>
              {/* <Button
                as={ReactLink}
                to='/registration'
                m={2}
                display={{ base: 'none', md: 'inline-flex' }}
                fontSize='sm'
                fontWeight={600}
                _hover={{ bg: 'purple.400' }}
                bg='purple.400'
                color='white'
              >
                Sign Up
              </Button> */}
            </>
          )}
            </Flex>
          <HStack as='nav' spacing={4} display={{ base: 'none', md: 'flex' }}>
            {links.map((link) => (
              <NavLink key={link.linkName} path={link.path}>
                {link.linkName}
              </NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems=''>
          <NavLink>
            <Icon
              as={BellIcon}
              h={8}
              w={8}
              alignSelf='center'
            />
          </NavLink>
        </Flex>
      </Flex>
      {/* {isOpen ? (
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as='nav' spacing={4}>
            {links.map((link) => (
              <NavLink key={link.linkName} path={link.path}>
                {link.linkName}
              </NavLink>
            ))}
            <NavLink key='sign up' path='/registration'>
              Sign up
            </NavLink>
          </Stack>
        </Box>
      ) : null} */}
    </Box>
  );
};

export default Navbar;
